/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 */

import 'zone.js';  // Included with Angular CLI

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// Import zone-flags if needed for specific browser support
// import './zone-flags';